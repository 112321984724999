import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import Metro01 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Settings_01.webp'
import Metro02 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Settings_02.webp'
import Metro03 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Settings_03.webp'
import Metro04 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Settings_04.webp'
import Metro05 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Settings_05.webp'
import Metro06 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Settings_06.webp'
import Metro07 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Settings_07.webp'

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="Enter the Settings menu to make global adjustments to your app."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="Network: Activate the Mobile Mode for all cameras and adjust the HTTPS timeout if you have troubles contacting your camera over HTTPS."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="The Image Preview menu contains settings that refer to the Multiview window with the preview images for all your cameras - nothing here affects the live video that you reach by clicking on one of those camera previews."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="Video: This menu affects the live video from your camera."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro05} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="Audio: The audio you get in live video is often a little bit quiet. Increase the volume here by a fixed percentage."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro06} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="Data&Sync: If you access your camera exclusively via the app, deactivate the synchronization to speed up the startup of the app. And activate app security features."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro07} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="App Settings"
          subtitle="Live Tile: Use the live tile feature to display a snapshot from your cameras in the Windows start menu. Use the Live Tile settings to set it up to your needs."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function SettingGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}


export default SettingGallery